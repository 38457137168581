import Tabs from "@/components/Tabs";
import { TabListRow } from "@/models/type";
import { Vue } from "vue-class-component";
import OrderBusiness from "./components/OrderBusiness";
import OrderFinance from "./components/OrderFinance";
import './index.scss'

export default class StatisticsOrder extends Vue {

  public activeName = 'business'

  public tabList: Array<TabListRow> = [
    {title: '业务分析', activeName: 'business'},
    {title: '财务分析', activeName: 'finance'},
  ]
  activechange(activeName: string): void {
    this.activeName = activeName
  }

  render(): JSX.Element {
    const slot = {
      business: () => <OrderBusiness />,
      finance: () => <OrderFinance />
    }
    return <div class="statistics">
      <el-card>
        <Tabs
          tabList={this.tabList}
          activeName={this.activeName}
          onActivechange={this.activechange}
          v-slots={slot}
        />
      </el-card>
    </div>
  }
}
