import api from "@/api";
import Search from "@/components/Search";
import { OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { Vue } from "vue-class-component"
import OrderChart from './charts/orderCharts'
import { h } from 'vue'
import './index.scss'
import { parseTime } from "@/utils";
import Pagination from "@/components/Pagination";

export default class OrderFinance extends Vue {
  private searchList: Array<SearchListRow> = []

  public charData = []
  public searchObj: any = {}
  public type = 'day'
  public productTypeList: OptionsRow[] = []
  public memberLevelList: OptionsRow[] = []

  public tableData: any[] = []
  public total = 0

  public orderStatusList: OptionsRow [] = [
    { name: '待付款', value: 0 },
    { name: '待发货', value: 1 },
    { name: '已发货', value: 2 },
    { name: '已完成', value: 3 },
    { name: '已关闭', value: 4 },
    { name: '无效订单', value: 5 },
    { name: '已评价', value: 6 },
  ]

  public payTypeList: OptionsRow[] = [
    { name: '微信支付', value: 2 },
    { name: '支付宝支付', value: 1 },
    { name: 'PayMe支付', value: 5 },
    { name: 'VISA支付', value: 3 },
    { name: 'Master支付', value: 4 }
  ]

  private tableList: Array<TableRow> = [
    { label: '订单编号', prop: 'orderSn' },
    { label: '收货人', prop: 'receiverName' },
    { label: '定价/实付', slot: {
      default: (scope: any) => <span>{scope.row.totalAmount} / {scope.row.payAmount}</span>
    } },
    { label: '支付方式', prop: 'payType', slot: {
      default: (scope: any): JSX.Element => <span>{ this.payTypeList.find(item => item.value === scope.row.payType)?.name }</span>
    } },
    { label: '创建时间', slot: {
      default: (scope: any) => <span>{ parseTime(new Date(scope.row.createTime).getTime()) }</span>
    } },
    { label: '状态', slot: {
      default: (scope: any) => <span>{this.orderStatusList.find(item => item.value === scope.row.status)?.name}</span>
    } }
  ]

  charDom(): JSX.Element {
    return <div class="order-business-char">
      <div class="order-business-char-header">
        <div class="order-business-char-title">销售额走势</div>
        <div class="order-business-char-choose">
          <div class={this.type === 'day' ? 'active': ''} onClick={this.typeSearch.bind(this, 'day')}>按天</div>
          <div class={this.type === 'month' ? 'active': ''} onClick={this.typeSearch.bind(this, 'month')}>按月</div>
        </div>
      </div>
      {h(OrderChart, { data: this.charData })}
    </div>
  }

  tableDom(): JSX.Element {
    return <div class="order-business-char">
      <div class="order-business-char-header">
        <div class="order-business-char-title">订单记录</div>
      </div>
      <el-table
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
            label={ item.label }
            prop={ item.prop }
            v-slots={item.slot}
            align="center"
          />
        ))}
      </el-table>
    </div>
  }

  typeSearch(type: string): void {
    this.type = type
    this.getList()
  }

  getData(): void {
    Promise.all([
      api.productCateListGorywithChildren(),
      api.memberLevelList()
    ]).then(res => {
      this.productTypeList = res[0].data.map(item => ({ name: item.name, value: item.id }))
      this.memberLevelList = res[1].data.map(item => ({ name: item.name, value: item.id }))
      this.searchList = [
        { label: '商品编号', type: 'input', submitKey: 'productSn', placeholder: '请输入会员编号' },
        { label: '商品类型', type: 'select', options: this.productTypeList, submitKey: 'productKind', placeholder: '请选择商品类型' },
        { label: '用户身份', type: 'select', options: this.memberLevelList, submitKey: 'memberLevel', placeholder: '请选择用户身份' }
      ]
    })
  }

  onSearch(row: any): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
    this.getOrderList()
  }

  getList(): void {
    api.systemOrder({selectType: 'money', type: this.type, ...this.searchObj}).then(res => {
      this.charData = res.data
    })
  }

  getOrderList(): void {
    this.tableData = [] // 防止样式不更新 重置一次
    this.$nextTick(() => {
      const __pagination = this.$refs.pagination as any
      const { pageNum, pageSize} = __pagination.paginationObj as PaginationRow
      api.orderList({ pageNum, pageSize, ...this.searchObj, status: 3 }).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    })
  }
  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return <div class="order-business">
      <Search list={this.searchList} onSearch={this.onSearch} />
      <el-divider />
      {this.charDom()}
      {this.tableDom()}
      <Pagination ref="pagination" total={ this.total } onSizeChange={ this.getOrderList } onCurrentChange={ this.getOrderList } />
    </div>
  }
  mounted(): void {
    this.getList()
    this.getOrderList()
  }
}
